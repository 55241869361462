html * {
  box-sizing: border-box;
}

html{
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#MainArea{
  margin: 0rem 0 0 0;
  background-color: #fafafa;
  min-height: calc(100vh - 7rem);
  background-image: radial-gradient(circle farthest-corner at center, #ffffff 0%, #e2e6f0 100%);
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 1.4rem;
  /*color: #eeeeee;*/
  position: relative;
  z-index: 1;
}

ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

.background{
  background-color: #ffffff;
  background-image: radial-gradient(#ffffff, #eee);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

@media screen and (min-width: 601px) {
  .desktop-only{
    display: none;
  }
}

@media (max-width:700px){
  .desktop_only{
    display: none;
  }
}

@media (min-width:701px){
  .mobile_only{
    display: none;
  }
}